@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@font-face {
  font-family: "Charlie Kingdom";
  src: url("../scss/custom/fonts/CharlieKingdom/CharlieKingdom.woff2")
      format("woff2"),
    url("../scss/custom//fonts/CharlieKingdom/CharlieKingdom.woff")
      format("woff");
}
@font-face {
  font-family: "Ding";
  src: url("../scss/custom/fonts/Ding-Heavy/Ding-Heavy.woff2") format("woff2"),
    url("../scss/custom/fonts/Ding-Heavy/Ding-Heavy.woff") format("woff");
}

.mini-widget {
  transform: scaleX(0.8) scaleY(0.9);
  .productVarient .review-slide .img-wrap {
    height: 140px;
    width: 140px;
  }
  .wideContent {
    align-items: center;
    .contentImage {
      max-height: 300px;
    }
  }
}
.text_deals_green {
  color: #2e9d5e !important;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    width: 35%;
    padding-top: 5px;

    h4 {
      font-family: "Ding";
      font-weight: 400;
      font-size: 42.5556px;
      line-height: 102%;
      letter-spacing: -0.01em;
      color: #7b0063;
      margin-bottom: 10px;
    }
    p {
      font-family: "Red Hat Display", sans-serif;
      font-weight: 800;
      font-size: 17px;
      line-height: 22px;
      color: #5f5f5f;
    }
    .product-price {
      .font-head-new {
        font-family: "Ding";
        font-weight: 400;
        font-size: 34.3537px;
        @media screen and (max-width: 768px) {
          font-size: 29.9856px;
        }
        line-height: 46px;
        color: #007551;
      }
      .old-price-new {
        font-family: "Charlie Kingdom";
        font-weight: 400;
        font-size: 16.8303px;
        @media screen and (max-width: 768px) {
          font-size: 16.2855px;
        }
        line-height: 23px;
        color: #252928;
      }
      .price-per-new {
        font-family: "Red Hat Display", sans-serif;
        font-weight: 900;
        font-size: 1.2197px;
        line-height: 23px;
        @media screen and (max-width: 768px) {
          font-size: 13.4627px;
        }
        letter-spacing: -0.03em;
        color: #007551;
      }
    }
    .btn-custom {
      margin-top: 15px;
      background: #ff0041;
      border-radius: 7.14894px;
      width: 200px;
      height: 42px;
      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }
  }
  .image-wrapper {
    width: 65%;
    max-height: 340px;
    img {
      object-fit: contain;
      max-height: 340px;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    .contents {
      width: 100% !important;
      padding: 0 10px;
      h4 {
        font-size: 43.4343px;
      }
      p {
        font-size: 13.2909px;
      }
      .btn-custom {
        width: 140px;
        height: 32.84px;
      }
    }
    .image-wrapper {
      width: 100% !important;
      margin-bottom: 4rem;
      img {
        transform: translateX(20px);
      }
    }
  }
}

.shortContents {
  // margin-top: 2rem;
  // margin-bottom: 2rem;
  display: flex !important;
  align-items: center;
  justify-content: space-evenly;
  // padding: 80px 0;
  .shortContentInner {
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .content {
    // max-width: 335px;
    @media screen and (max-width: 768px) {
      justify-content: unset;
      margin-bottom: 20px;
    }
    img {
      width: 100px;
      height: 100px;
      @media screen and (max-width: 768px) {
        width: 90px;
        height: 90px;
      }
    }
    h4 {
      font-family: "Charlie Kingdom";
      font-weight: 400;
      font-size: 20.8942px;
      line-height: 23px;
      color: #ffffff;
      white-space: inherit;
    }
  }
  .testimonialWrapp > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.wideContent {
  display: flex;
  &.left {
    flex-direction: row-reverse;
  }
  &.right {
    flex-direction: row;
  }
  &.top {
    flex-direction: column-reverse;
    .content {
      width: 80%;
      margin: auto;
    }
    .contentImage {
      width: 100%;
      max-height: 340px;
      img {
        max-height: 340px;
        object-fit: cover;
      }
    }
  }
  &.bottom {
    flex-direction: column;
    .content {
      width: 80%;
    }
    .contentImage {
      width: 100%;
      max-height: 400px;
      img {
        max-height: 400px;
        object-fit: cover;
      }
    }
  }
  .shortContent-preview {
    .form-group {
      width: 100%;
      textarea {
        min-height: 95px;
      }
    }
  }
  .content {
    text-align: center;
    width: 50%;
    padding: 40px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    &.withImg {
      // padding: 0 80px;
      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }
    h4 {
      font-family: "Ding";
      font-weight: 400;
      font-size: 38.5939px;
      line-height: 116.5%;
      letter-spacing: -0.01em;
      // padding-bottom: 20px;
    }
    p {
      font-family: "Red Hat Display", sans-serif;
      font-weight: 800;
      font-size: 17px;
      line-height: 22px;
      text-align: center;
      color: #5f5f5f;
    }
    button {
      height: 42px;
      background: #ff0041;
      border-radius: 7.14894px;
    }
    @media screen and (max-width: 768px) {
      margin: 4rem auto;
      width: 80%;
      h4 {
        font-size: 25.44px;
      }
      p {
        font-size: 13.2px;
      }
      button {
        font-size: 11px;
      }
    }
  }

  .contentImage {
    width: 50%;
    max-height: 600px;
    img {
      width: 100%;
      height: 100%;
      max-height: 600px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse !important;
    .contentImage {
      width: 100%;
      max-height: 400px;
      img {
        max-height: 400px;
        object-fit: cover;
      }
    }
  }
}
.related-products .shoplist-child {
  min-height: 309px !important;
}
.mobileContainer {
  height: 260px;
}
.banner-img {
  position: relative;
  clear: both;
  border-radius: 10px;
  overflow: hidden;
}

.mobileBanner {
  width: 100% !important;
  // height: 155px !important;
  margin-bottom: 10px !important;
  border-radius: 5px !important;
}
.object-start {
  object-position: left;
}
.fit-cover {
  object-fit: cover !important;
}
.image-grid {
  padding: 4rem 2rem;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  .gridItem {
    padding: 10px;
    max-height: 340px;
    margin-bottom: 10px;
    img {
      object-fit: contain;
      // height: 80%;
      max-height: 340px;
      width: 80%;
    }
  }
}
.video_banner {
  // margin-top: 4rem;
  h4 {
    font-family: "Ding";
    font-weight: 400;
    font-size: 38.5939px;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      font-size: 25.4425px;
    }
  }
  .react-player {
    // margin: 6rem 0;
    position: relative;
    width: fit-content;
    margin: auto;
    // margin-bottom: 10rem;
    // margin-top: 10rem;
    @media screen and (max-width: 768px) {
      margin-bottom: 3rem;
      margin-top: 3rem;
    }
    .el {
      position: absolute;
      z-index: 0;
      &.el1 {
        right: -60px;
        bottom: -70px;
        @media screen and (max-width: 768px) {
          width: 81.9px;
          height: 81.9px;
          right: -30px;
          bottom: -30px;
        }
      }
      &.el2 {
        left: -70px;
        top: -40px;
        z-index: 2;
        @media screen and (max-width: 768px) {
          width: 71.77px;
          height: 71.77px;
          left: -30px;
          top: -20px;
        }
      }
      &.el3 {
        right: -65px;
        bottom: 90px;
        @media screen and (max-width: 768px) {
          width: 25.33px;
          height: 25.33px;
          right: -30px;
          bottom: 55px;
        }
      }
      &.el4 {
        left: -50px;
        top: 110px;
        @media screen and (max-width: 768px) {
          width: 16.89px;
          height: 16.89px;
          left: -25px;
          top: 55px;
        }
      }
    }
    .react-player-video {
      z-index: 1;
      iframe {
        border-radius: 44.6498px;
        @media screen and (max-width: 768px) {
          border-radius: 21.5311px;
        }
      }
      .react-player__preview {
        border-radius: 44.6498px;
        @media screen and (max-width: 768px) {
          border-radius: 21.5311px;
        }
      }
    }
    .ytp-chrome-top {
      display: none !important;
    }
  }
}
.productVarienth2 {
  font-weight: 400;
  font-family: "Ding";
  font-size: 38px;
  letter-spacing: -0.01em;
  color: #7b0063;
  margin-bottom: 30px;
  // text-align: center;
}
.productVarient {
  .productVarienth2 {
    font-weight: 400;
    font-family: "Ding";
    font-size: 38px;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin-bottom: 30px;
    // text-align: center;
  }
  .review-slide {
    background: #ffff;
    padding: 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // min-height: 300px;
    // width: 280px;
    .img-wrap {
      height: 200px;
      width: 200px;
      margin: 0 auto;
      padding: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h4 {
      font-family: unset !important;
      font-weight: 400;
      font-size: 14px !important;
      text-align: start !important;
    }
  }
}
.customerReview {
  .swiper-slide-active {
    .review-slide {
      // max-height: 340px;
      background: rgba(123, 0, 99, 0.06);
      border-radius: 73px;
      transform: scale(1);
      @media screen and (max-width: 768px) {
        border-radius: 40px;
      }
    }
  }
  // margin-top: 5rem;
  // margin-bottom: 5rem;
  h4 {
    font-family: "Ding";
    font-weight: 400;
    font-size: 38.5939px;
    line-height: 116.5%;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin: 0 8rem;
    margin-bottom: 5rem;
    @media screen and (max-width: 768px) {
      font-size: 38.5939px;
      text-align: center;
      width: 90%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
  .review-slide {
    background: rgba(123, 0, 99, 0.06);
    border-radius: 5%;
    transform: scale(1);
    @media screen and (max-width: 768px) {
      border-radius: 40px;
    }
  }
  .review-slide {
    background: rgba(244, 244, 244, 0.65);
    border-radius: 35.0962px;
    padding: 50px;
    text-align: center;
    transform: scale(0.788);
    transition: all ease-in-out 0.3s;
    img {
      width: 70px;
      height: 70px;
    }
    p {
      font-family: "Red Hat Display", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      color: #000000;
    }
    h4 {
      font-family: "Charlie Kingdom";
      font-weight: 400;
      font-size: 22.4842px;
      line-height: 25px;
      text-transform: capitalize;
      color: #1e1e1e;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (max-width: 768px) {
      h4 {
        font-size: 13.5px;
      }
      p {
        font-size: 13px;
      }
      .review-slide {
        padding: 20px !important;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.testimonials {
  margin: 0 5rem;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
  h4 {
    font-family: "Ding";
    font-weight: 400;
    font-size: 38.5939px;
    line-height: 116.5%;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      font-size: 26.6836px;
      text-align: center;
      width: 80%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
  .accordion-header {
    font-family: "Charlie Kingdom";
    font-weight: 400;
    font-size: 18.8867px;
    line-height: 21px;
    text-transform: capitalize;
    color: #ffffff;
    @media screen and (max-width: 768px) {
      font-size: 13.0582px;
    }
  }
  .accordion-item {
    background-color: #f4f4f4;
    margin-bottom: 10px;
  }
  .accordion-button {
    border: none;
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }
  .accordion-collapse {
    padding: 10px;
  }
  .accordion-button:not(.collapsed) {
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
    color: #fff;
    background-color: #7b0063;
    // .accordion-button:not(.collapsed)::after {
    //   background-image: url("../imgs/landing/icons/arrowUp.png") !important;
    // }
  }
  .accordion-body {
    font-family: "Red Hat Display", sans-serif;
    font-weight: 400;
    font-size: 16px;
    @media screen and (max-width: 768px) {
      font-size: 13.0582px;
    }
    line-height: 25px;
    color: #000000;
  }
}
.react-player__preview {
  border-radius: 15px;
}
.select-option {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  padding: 0px 5px;
}

.block-picker {
  div {
    &:nth-child(2) {
      height: 50px !important;
    }
  }
}
.scroll-bar-hide {
  &::-webkit-scrollbar {
    display: none;
  }
}
.close-icons-wrap {
  position: absolute;
  top: 2px;
  right: 2px;
  background: aliceblue;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .close-icons {
    color: red;
    font-size: 22px;
  }
}

.chrome-picker {
  border-radius: 10px !important;
  border: 1px solid #f3f3f3 !important;
  box-shadow: none !important;
}
.modal-footer-wrap {
  background: #f4f4f4;
  padding: 15px 10px;
}

.main-body {
  padding: 1.5rem;
  padding-bottom: 0 !important;
}

.section-modal {
  max-width: 60% !important;
  // .modal-content {
  //   // padding: 1.5rem;
  // }
}
.color-picker {
  .block-picker {
    box-shadow: none !important;
    border: 1px solid rgb(248, 248, 251);
    input {
      height: 35px !important;
    }
  }
}
.gridSize {
  .form-group {
    margin-right: 10px;
  }
}
.product-card {
  min-height: 400px;
  width: 20%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.product-img {
  height: 270px;
  width: 190px;
}
.product-copy {
  position: absolute;
  right: 10px;
  top: 10px;
}

.common-section {
  background: #f8f9fa;
  padding: 20px;
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;
}
.common-section-1 {
  .form-group {
    width: 100px;
  }
  .tranc {
    transform: scale(0.9);
    margin-top: -200px;
  }
}

.platform {
  flex-direction: column-reverse;
}
.icon {
  font-size: x-large;
  cursor: pointer;
}

.myNewArrow .swiper-button-prev {
  &:after {
    position: relative;
    left: -1px;
  }
}

.myNewArrow .swiper-button-next {
  &:after {
    position: relative;
    left: 1px;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.myNewArrow .swiper-button-next,
.myNewArrow .swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.myNewArrow .swiper-button-prev.swiper-button-disabled,
.myNewArrow .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-container-rtl .swiper-button-next {
  left: -16px;
  right: auto;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.myNewArrow .swiper-button-next,
.myNewArrow .swiper-container-rtl .swiper-button-prev {
  right: -16px;
  left: auto;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-button-next {
  top: 60%;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0);
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 50%;
  color: blue;
  font-weight: 700;
  outline: 0;
  transition: background-color 0.2s ease, color 0.2s ease;

  &::after {
    font-size: 16px;
  }
}
